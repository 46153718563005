import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpParams, HttpHeaders, HttpEvent, HttpErrorResponse } from '@angular/common/http';
import { Observable } from 'rxjs';
import { Auth0Service } from '../Auth0/auth0.service';
import { UserService } from '../User/user.service';
import { map, catchError } from 'rxjs/operators';



@Injectable({
  providedIn: 'root'
})
export class FileService 
{

  constructor(public router: Router, public serviceAuth: Auth0Service, public serviceUser: UserService, private httpClient: HttpClient) 
  { 

  }

  public Send_UploadFileV2(file: File, api_url: string, paramsIDToken: HttpParams): Observable<any>
  {

    // console.log('File: ', File)
    // console.log("typeof File: ", typeof File)

    let formData: FormData = new FormData();
    formData.append('file', file, file.name);

    // console.log('formData: ', formData)
    // console.log("typeof formData: ", typeof formData)

    var url: string = api_url;

    // var idToken = this.serviceAuth.IdToken;
    // let paramsIDToken = new HttpParams().set('id_token', idToken);

    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;

    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      // 'Content-Type': 'multipart/form-data',
      // 'Accept': 'application/json'
    });

    // headers=headers.append('Access-Control-Allow-Origin', '*');

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      // withCredentials: true,
      responseType: 'text',
      reportProgress: true,
      observe: 'events'
    };

    // console.log('headers: ', headers)
    // console.log("typeof headers: ", typeof headers)


    // console.log('requestOptions: ', requestOptions)
    // console.log("typeof requestOptions: ", typeof requestOptions)

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.post<any>(url, formData, requestOptions);
  }
}
