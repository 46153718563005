import { DEV_MODE, TEST_DEPLOY } from './Defines';

interface Interface_ConfigAuth0
{
    CLIENT_ID: string;
    DOMAIN: string;
    CALLBACK_URL: string;
    AUDIENCE: string
}

export const CONFIG_AUTH0: Interface_ConfigAuth0 =
{
    CLIENT_ID: 'mAjMSj6IhAhy2TascR2IfAeYGxInp1rU',
    DOMAIN: 'dev--dv3szma.auth0.com',

    // --- LOCAL OR LIVE ---
    CALLBACK_URL: (DEV_MODE || TEST_DEPLOY) ?
      (DEV_MODE ? 'http://localhost:3000/callback' :       // Local Server
       'http://www.craniorate.com/callback') :             // Test Server
      'https://www.craniorate.org/callback',               // Live Server

    AUDIENCE: "https://localhost:5001/"
};
