import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import * as auth0 from 'auth0-js';
import { CONFIG_AUTH0 } from '../../Configurations/Config_Auth0';

@Injectable({
  providedIn: 'root'
})
export class Auth0Service
{

  private m_idToken: string;
  private m_accessToken: string;
  private m_expiresAt: number;

  public m_username: string;

  auth0 = new auth0.WebAuth({
    clientID: CONFIG_AUTH0.CLIENT_ID,
    domain: CONFIG_AUTH0.DOMAIN,
    responseType: 'token id_token',
    redirectUri: CONFIG_AUTH0.CALLBACK_URL,
    audience: CONFIG_AUTH0.AUDIENCE
  });

  constructor(public router: Router)
  {
    //console.log("Auth0Service ctor");
    this.m_idToken = '';
    this.m_accessToken = '';
    this.m_expiresAt = 0;
    this.m_username = "unknown user";
  }

  get AccessToken(): string
  {
    return this.m_accessToken;
  }

  get IdToken(): string
  {
    return this.m_idToken;
  }

  public Login(): void
  {
    //console.log("Auth0Service.Login()");
    this.auth0.authorize();
  }

  public HandleAuthentication(): void {
    console.log("Auth0Service.HandleAuthentication()");
    this.auth0.parseHash((err, authResult) => {
      console.log("entering parseHash lambda...");
      if (authResult && authResult.accessToken && authResult.idToken) {
        this.LocalLogin(authResult);

        this.auth0.client.userInfo(authResult.accessToken, (err, user) => {
          console.log("email: " + user.email);
          this.m_username = user.email;
        });

        this.router.navigate(['/main']);

      } else if (err) {
        this.router.navigate(['/main']);
        console.log(err);
        alert(`Error: ${err.error}. Check the console for further details.`);
      }
      else {
        console.log("HandleAuthentication with empty input (no err or authResult)");
      }
      console.log("leaving parseHash lambda...");
    });
    console.log("Leaving Auth0Service.HandleAuthentication");
  }

  private LocalLogin(authResult): void {
    // Set the time that the access token will expire at
    const expiresAt = (authResult.expiresIn * 1000) + Date.now();
    this.m_accessToken = authResult.accessToken;
    this.m_idToken = authResult.idToken;
    this.m_expiresAt = expiresAt;
  }

  public RenewTokens(): void {
    this.auth0.checkSession({}, (err, authResult) => {
       if (authResult && authResult.accessToken && authResult.idToken) {
         this.LocalLogin(authResult);
       } else if (err) {
         alert(`Could not get a new token (${err.error}: ${err.error_description}).`);
         this.Logout();
       }
    });
  }

  public Logout(): void {
    //console.log("Auth0Service.Logout()");
    // Remove tokens and expiry time
    this.m_accessToken = '';
    this.m_idToken = '';
    this.m_expiresAt = 0;

    this.auth0.logout({
      returnTo: window.location.origin
    });
  }

  public IsAuthenticated(): boolean {
    // Check whether the current time is past the
    // access token's expiry time
    //console.log("Auth0Service.IsAuthenticated()\n\taccessToken, expiration: ", String(this.m_accessToken), String(this.m_expiresAt));
    return this.m_accessToken && Date.now() < this.m_expiresAt;
  }
}
