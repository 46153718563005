import { Component, OnInit, Input, AfterViewInit, ViewChild, ElementRef, HostListener, OnChanges } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import * as THREE from 'three';
import * as THREE_FULL from 'three-full';
import * as DEFINES from 'src/app/Configurations/Defines';


import { PredictionService } from 'src/app/Services/Prediction/prediction.service';
import { Interface_InfoPrediction_Basic } from 'src/app/Models/InfoPrediction';
import { Vector3 } from 'three';

@Component({
  selector: 'app-prediction-visualize',
  templateUrl: './prediction-visualize.component.html',
  styleUrls: ['./prediction-visualize.component.css']
})
export class PredictionVisualizeComponent implements OnInit, AfterViewInit, OnChanges  
{

  public imageColorBar = '../../../assets/visualization/color_bar.png';

  public FIELD_OF_VIEW: number = 60;
  public NEAR_CLIPPING_PLANE: number= 1;
  public FAR_CLIPPING_PLANE = 1100;
  public SIZE_AXE = 1100;
  public DEFAULT_ZOOM = 3;
  public COLOR_BACKGROUND: THREE.Color = new THREE.Color( 0x000000 );

  public Z_POSITION_OBJECT: number = -500;

  @Input() public infoPrediction: Partial<Interface_InfoPrediction_Basic>;
  @Input() public isPreview: boolean = false;

  // private isDownloading = false;



  @ViewChild('refCanvas')
  private refCanvas: ElementRef;

  private renderer: THREE.WebGLRenderer;
  private camera: THREE.PerspectiveCamera;
  private cameraTarget: THREE.Vector3;
  private cameraOrth: THREE.OrthographicCamera;
  private scene: THREE.Scene;
  private objectData: THREE.Object3D;
  private scalarZoom: number = 1.0;



  private isMouseDragging: boolean = false;
  private posX_Pre: number = 0;
  private posY_Pre: number = 0;
  private speedRotation: number = 0.02;

  private aspectRatio: number = 4/3;


  constructor(public ref_modal: BsModalRef, public servicePREDICTION: PredictionService) 
  {

  }

  ngOnInit() 
  {
    this.infoPrediction = this.servicePREDICTION.info_prediction;
    this.RenderScene = this.RenderScene.bind(this);
    this.OnColladaModelLoadingCompleted = this.OnColladaModelLoadingCompleted.bind(this);
    this.OnOBJModelLoadingCompleted = this.OnOBJModelLoadingCompleted.bind(this);
    this.OnVisualizationDataDownloadFinished = this.OnVisualizationDataDownloadFinished.bind(this);
  }

  ngAfterViewInit()
  {

    this.CreateScene();
    this.CreateCamera();
    this.StartRendering();
    this.InitModel();
    this.OnResize(null);
  }

  ngOnChanges(changes: import("@angular/core").SimpleChanges): void
  {
    this.ReestView();
  }

  

  private ReestView(): void
  {
    if(this.scene != null)
    {
      this.cameraOrth.position.set(0, 0, 0);
      this.InitModel();
      this.OnResize(null);
    }

  }

  public get GetCanvas(): HTMLCanvasElement 
  {
    return this.refCanvas.nativeElement;
  }

  private CreateScene() 
  {
    this.scene = new THREE.Scene();
    this.scene.background = this.COLOR_BACKGROUND;
    this.scene.add(new THREE.AxesHelper(this.SIZE_AXE));

    // var loaderCollada = new THREE_FULL.ColladaLoader();
    // loaderCollada.load('assets/MeshesTemp/multimaterial.dae', this.OnColladaModelLoadingCompleted);
    
  }

  private InitModel()
  {
    if(this.infoPrediction != null)
    {
      this.ReloadModel();
    }
    
  }

  private OnColladaModelLoadingCompleted(collada) 
  {
    var modelScene = collada.scene;
    this.scene.add(modelScene);
    this.RenderScene();
  }

  // compute vertex normals for disabling flat shading, following 
  // (https://discourse.threejs.org/t/solved-how-can-we-smooth-model-loaded-with-objloader-i-e-not-flat-shading/5531/7)
  public setSmoothGeometry(obj) {
    obj.traverse(node => {
        if ('geometry' in node) {
            const tempGeometry = new THREE.Geometry().fromBufferGeometry( node.geometry );
            tempGeometry.mergeVertices();
            tempGeometry.computeVertexNormals();
            node.geometry = new THREE.BufferGeometry().fromGeometry( tempGeometry );
        }
    })
  }

  public ReloadModel()
  {
    if(this.objectData != undefined && this.objectData != null)
    {
      this.scene.remove(this.objectData);
    }
    

    if(this.servicePREDICTION.isDownloadingVisualizationData == false && 
      this.infoPrediction != null && 
      this.infoPrediction.id != null && this.infoPrediction.id.length > 0 && 
      this.infoPrediction.id_visualization != null && this.infoPrediction.id_visualization.length > 0)
    {
      let data: string = this.servicePREDICTION.LoadVisualizationDataFromLocal(this.infoPrediction.id_visualization);

      if (data != null)
      {
        let loaderOBJ = new THREE_FULL.OBJLoader2();
        let objectData: THREE.Object3D = loaderOBJ.parse(data);

        this.setSmoothGeometry(objectData);

        this.removeReflection(objectData)

        this.OnOBJModelLoadingCompleted(objectData);

        this.OnFrontButton();

        this.OnZoomChange(this.DEFAULT_ZOOM);

      }
      else
      {
        if(this.servicePREDICTION.isDownloadingVisualizationData == false && 
          this.infoPrediction != null && 
          this.infoPrediction.id != null && this.infoPrediction.id.length > 0 && 
          this.infoPrediction.id_visualization != null && this.infoPrediction.id_visualization.length > 0)
        {

          this.servicePREDICTION.SetVisualizationDownloadingStatus(true);

          this.servicePREDICTION.Send_DownloadVisualization(this.infoPrediction.id, this.infoPrediction.id_visualization, this.OnVisualizationDataDownloadFinished).subscribe(
            respSuccess=>{
              // console.log(respSuccess);

              let data = respSuccess;

              this.servicePREDICTION.SaveVisualizationDataToLocal(this.infoPrediction.id_visualization, data);
          
              this.OnVisualizationDataDownloadFinished(data);

              this.OnFrontButton();

              this.OnZoomChange(this.DEFAULT_ZOOM);

              this.servicePREDICTION.SetVisualizationDownloadingStatus(false);

            },
            respMessageError=>{
              console.log(respMessageError);

              this.servicePREDICTION.SetVisualizationDownloadingStatus(false);

              alert("Visualization Download: Failed");
            }
          );
        }
        else
        {
          alert("You are already downloading skull data.\nPlease wait for the downloading finished first!");
        }
        
      }
    }
    
  }

  private OnOBJModelLoadingCompleted(objectData) 
  {
    if(this.objectData != undefined && this.objectData != null)
    {
      this.scene.remove(this.objectData);
    }
    
    this.refCanvas.nativeElement.style.width = "90%";
    this.refCanvas.nativeElement.style.height = "90%";
    let widthCurrent = this.refCanvas.nativeElement.clientWidth;
    let heightCurrent = this.refCanvas.nativeElement.clientHeight;

    this.renderer.setSize(widthCurrent, widthCurrent/this.aspectRatio);

    objectData.position.x = 0;
    objectData.position.y = 0;
    objectData.position.z = this.Z_POSITION_OBJECT;

    this.objectData = objectData;
    this.scene.add(objectData);
          
    this.RenderScene();
  }

  public removeReflection(obj) {
    obj.traverse(node => {
        if ('material' in node) {
            node.material.roughness = 1;
        }
    })
  }


  private OnVisualizationDataDownloadFinished(data)
  {
    this.servicePREDICTION.SaveVisualizationDataToLocal(this.infoPrediction.id_visualization, data);

    let loaderOBJ = new THREE_FULL.OBJLoader2();
    let objectData: THREE.Object3D = loaderOBJ.parse(data);

    this.setSmoothGeometry(objectData);

    this.removeReflection(objectData)

    this.OnOBJModelLoadingCompleted(objectData);
  }

  private CreateCamera() 
  {
    
    var frustumSize = 500;
    var aspect = this.GetAspectRatio();

    this.cameraOrth = new THREE.OrthographicCamera(
      frustumSize * this.aspectRatio / -2, 
      frustumSize * this.aspectRatio / 2, 
      frustumSize / 2,
      frustumSize / -2,
      1,
      1000);

    this.cameraOrth.position.set(0, 0, 0);

    // var dirLightOrth = new THREE.DirectionalLight( 0x606060 );
    var dirLightOrth = new THREE.DirectionalLight( 0xFFFFFF );
    dirLightOrth.position.set( 200, 200, 1000 );

    this.cameraOrth.add( dirLightOrth );
    // this.cameraOrth.add( dirLightOrth.target );

    // var ambientLight = new THREE.AmbientLight( 0x404040 ); // soft white light
    // var ambientLight = new THREE.AmbientLight( 0x707070 ); // soft white light
    var ambientLight = new THREE.AmbientLight( 0xffffff, 1 ); // default white light
    this.scene.add( ambientLight );

    this.scene.add(this.cameraOrth);

  }

  private GetAspectRatio(): number 
  {
    let height = this.refCanvas.nativeElement.clientHeight;
    if (height === 0) {
        return 0;
    }
    return this.refCanvas.nativeElement.clientWidth / this.refCanvas.nativeElement.clientHeight;
  }

  private StartRendering() 
  {
    this.renderer = new THREE.WebGLRenderer({
        canvas: this.refCanvas.nativeElement,
        antialias: true
    });
    this.renderer.setPixelRatio(devicePixelRatio);
    this.renderer.setSize(this.refCanvas.nativeElement.clientWidth, this.refCanvas.nativeElement.clientHeight);

    this.renderer.shadowMap.enabled = true;
    this.renderer.shadowMap.type = THREE.PCFSoftShadowMap;
    this.renderer.setClearColor(0xffffff, 1);
    this.renderer.autoClear = true;

    let component: PredictionVisualizeComponent = this;

    (function render() 
    {
        component.RenderScene();
    }());
  }

  public RenderScene() 
  {
    // this.cameraOrth.lookAt(this.scene.position);
    this.renderer.render(this.scene, this.cameraOrth);
  }

  public OnTopButton()
  {
    this.objectData.rotation.x = 0;
    this.objectData.rotation.y = 0;
    this.objectData.rotation.z = 0;

    // this.meshVTK.rotation.x = 0;
    // this.meshVTK.rotation.y = 0;
    // this.meshVTK.rotation.z = 0;
    this.RenderScene();
  }

  public OnFrontButton()
  {
    this.objectData.rotation.x = 0;
    this.objectData.rotation.y = 0;
    this.objectData.rotation.z = 0;

    this.objectData.rotation.x = Math.PI*1.5;

    // this.meshVTK.rotation.x = 0;
    // this.meshVTK.rotation.y = 0;
    // this.meshVTK.rotation.z = 0;

    // this.meshVTK.rotation.x = Math.PI*1.5;

    this.RenderScene();
  }

  public OnBackButton()
  {
    this.objectData.rotation.x = 0;
    this.objectData.rotation.y = 0;
    this.objectData.rotation.z = 0;

    this.objectData.rotation.x = Math.PI / 2;
    this.objectData.rotation.y = Math.PI;
  
    // this.meshVTK.rotation.x = 0;
    // this.meshVTK.rotation.y = 0;
    // this.meshVTK.rotation.z = 0;

    // this.meshVTK.rotation.x = Math.PI / 2;
    // this.meshVTK.rotation.y = Math.PI;

    this.RenderScene();
  }

  public OnLeftButton()
  {
    this.objectData.rotation.x = 0;
    this.objectData.rotation.y = 0;
    this.objectData.rotation.z = 0;

    this.objectData.rotation.x = Math.PI / 2;
    this.objectData.rotation.y = Math.PI;
    this.objectData.rotation.z = Math.PI / 2;

    // this.meshVTK.rotation.x = 0;
    // this.meshVTK.rotation.y = 0;
    // this.meshVTK.rotation.z = 0;
          
    // this.meshVTK.rotation.x = Math.PI / 2;
    // this.meshVTK.rotation.y = Math.PI;
    // this.meshVTK.rotation.z = Math.PI / 2;

    this.RenderScene();
  }

  public OnRightButton()
  {
    this.objectData.rotation.x = 0;
    this.objectData.rotation.y = 0;
    this.objectData.rotation.z = 0;

    this.objectData.rotation.x = Math.PI / 2;
    this.objectData.rotation.y = Math.PI;
    this.objectData.rotation.z = Math.PI * 1.5;

    // this.meshVTK.rotation.x = 0;
    // this.meshVTK.rotation.y = 0;
    // this.meshVTK.rotation.z = 0;

    // this.meshVTK.rotation.x = Math.PI / 2;
    // this.meshVTK.rotation.y = Math.PI;
    // this.meshVTK.rotation.z = Math.PI * 1.5;

    this.RenderScene();
  }

  public OnBottomButton()
  {
    this.objectData.rotation.x = 0;
    this.objectData.rotation.y = 0;
    this.objectData.rotation.z = 0;

    this.objectData.rotation.x = Math.PI;

    // this.meshVTK.rotation.x = 0;
    // this.meshVTK.rotation.y = 0;
    // this.meshVTK.rotation.z = 0;
    
    // this.meshVTK.rotation.x = Math.PI;
    // // this.meshVTK.rotation.y = Math.PI;

    this.RenderScene();
  }

  public OnZoomChange(valueZoomScale: number)
  {
    // this.meshVTK.scale.setScalar(valueZoomScale);
    // this.scalarZoom = valueZoomScale;
    // this.meshVTK.scale.setScalar(this.scalarZoom);
    // To do: change scale for object
    this.objectData.scale.setScalar(valueZoomScale);
    this.RenderScene();

  }

  @HostListener('mousedown', ['$event'])
  OnMouseDown(event: MouseEvent) 
  {
    var posX = event.clientX;
    var posY = event.clientY;

    var rect = this.refCanvas.nativeElement.getBoundingClientRect();

    if(this.CheckMousePositionInCanvas(posX, posY, rect))
    {
      this.isMouseDragging = true;
    }
  }

  @HostListener('touchstart', ['$event'])
  OnTouchStart(event: any) 
  {
    if(event.targetTouches != undefined && event.targetTouches != null && event.targetTouches.length == 1)
    {
      var posX = event.targetTouches[0].clientX;
      var posY = event.targetTouches[0].clientY;
    


      var rect = this.refCanvas.nativeElement.getBoundingClientRect();

      if(this.CheckMousePositionInCanvas(posX, posY, rect))
      {
        this.isMouseDragging = true;
        this.posX_Pre = posX;
        this.posY_Pre = posY;
      }
    }
  }

  @HostListener('mouseup', ['$event'])
  OnMouseUp(event: MouseEvent) 
  {
    this.isMouseDragging = false;
  }

  @HostListener('touchend', ['$event'])
  OnTouchEnd(event: any) 
  {
    this.isMouseDragging = false;
  }

  @HostListener('touchcancel', ['$event'])
  OnTouchCancel(event: any) 
  {
    this.isMouseDragging = false;
  }

  @HostListener('mousemove', ['$event'])
  OnMouseMove(event: MouseEvent) 
  {
    var posX = event.clientX;
    var posY = event.clientY;
    if (this.isMouseDragging) 
    {
      // The rotation speed factor
      // dx and dy here are how for in the x or y direction the mouse moved
      // var factor = 10/state.canvas.height;
      // var factor = 10/500;
      var dx = this.speedRotation * (posX - this.posX_Pre);
      var dy = this.speedRotation * (posY - this.posY_Pre);

      // update the latest angle
      this.objectData.rotation.z = this.objectData.rotation.z + dx;
      this.objectData.rotation.x = this.objectData.rotation.x + dy;

      // console.log("=====================");
      // console.log("Z: " + this.objectData.rotation.z.toString());
      // console.log("X: " + this.objectData.rotation.x.toString());

      this.RenderScene();

    }
    // update the last mouse position
    this.posX_Pre = posX;
    this.posY_Pre = posY;
  }

  @HostListener('touchmove', ['$event'])
  OnTouchMove(event: any) 
  {


    if (this.isMouseDragging) 
    {
      if(event.targetTouches != undefined && event.targetTouches != null && event.targetTouches.length == 1)
      {
        
        var posX = event.targetTouches[0].clientX;
        var posY = event.targetTouches[0].clientY;

        var dx = this.speedRotation * (posX - this.posX_Pre);
        var dy = this.speedRotation * (posY - this.posY_Pre);

        // The rotation speed factor
        // dx and dy here are how for in the x or y direction the mouse moved
        // var factor = 10/state.canvas.height;
        // var factor = 10/500;

        var dx = this.speedRotation * (posX - this.posX_Pre);
        var dy = this.speedRotation * (posY - this.posY_Pre);
  
        // update the latest angle
        this.objectData.rotation.z = this.objectData.rotation.z + dx;
        this.objectData.rotation.x = this.objectData.rotation.x + dy;
        
        this.RenderScene();

        // update the last mouse position
        this.posX_Pre = posX;
        this.posY_Pre = posY;
      }
    }

  }

  SwipeHandler() 
  {
    alert('Swipe handled!');
  }

  private CheckMousePositionInCanvas(posXMouse: number, posYMouse: number, rectCanvas: DOMRect): boolean
  {
    let xStart = rectCanvas.left;
    let xEnd = rectCanvas.right;
    let yStart = rectCanvas.top;
    let yEnd = rectCanvas.bottom;

    if ((posXMouse > xStart && posXMouse < xEnd) && (posYMouse > yStart && posYMouse < yEnd))
    {
        return true;
    }
    return false;
  }

  @HostListener('window:resize', ['$event'])
  public OnResize(event: Event) 
  {
    this.refCanvas.nativeElement.style.width = "90%";
    this.refCanvas.nativeElement.style.height = "90%";
    var widthCurrent = this.refCanvas.nativeElement.clientWidth;
    var heightCurrent = this.refCanvas.nativeElement.clientHeight;

    this.renderer.setSize(widthCurrent, widthCurrent/this.aspectRatio);
    
    this.RenderScene();
  }










  OnLoadVisualizationTest()
  {

    if(this.servicePREDICTION.isDownloadingVisualizationData == false && 
      this.infoPrediction != null && 
      this.infoPrediction.id != null && this.infoPrediction.id.length > 0 && 
      this.infoPrediction.id_visualization != null && this.infoPrediction.id_visualization.length > 0)
    {
      this.servicePREDICTION.SetVisualizationDownloadingStatus(true);

      this.servicePREDICTION.Send_DownloadVisualization(this.infoPrediction.id, this.infoPrediction.id_visualization, this.OnVisualizationDataDownloadFinished).subscribe(
        respSuccess=>{
          // console.log(respSuccess);
  
          let data = respSuccess;
  
          this.servicePREDICTION.SaveVisualizationDataToLocal(this.infoPrediction.id_visualization, data);
      
          this.OnVisualizationDataDownloadFinished(data);
  
          this.servicePREDICTION.SetVisualizationDownloadingStatus(false);
  
        },
        respMessageError=>{
  
          console.log(respMessageError);
  
          alert("Visualization Download: Failed");
        }
      );
    }
    
  }


  OnLocalTestClick()
  {
 
    var scene = this.scene;
    var renderer = this.renderer;
    var camera = this.cameraOrth;

    var ambientLight = new THREE.AmbientLight( 0xcccccc, 0.4 );
    scene.add( ambientLight );

    var pointLight = new THREE.PointLight( 0xffffff, 0.8 );
    camera.add( pointLight );
    
    var loader = new THREE_FULL.OBJLoader2();

    // loader.load('../../../assets/windmill.obj',
    loader.load('../../../assets/test_vis.obj',
      function ( obj3d ) 
      {
        obj3d.position.set(0,0,-50);
        scene.add( obj3d );
        renderer.render(scene, camera);
      },
      // called when loading is in progresses
      function ( xhr ) {
    
        console.log( ( xhr.loaded / xhr.total * 100 ) + '% loaded' );
    
      },
      // called when loading has errors
      function ( error ) {
    
        console.log( 'An error happened' );
    
      }
    );
  }

  FilterMaxDeviationMM(max_deviation_mm: string) {
    if (max_deviation_mm != null && max_deviation_mm != undefined) {
      var mdmm = parseFloat(max_deviation_mm);
      if (mdmm != 0) {
        return mdmm.toFixed(2);
      } else {
        return 'N/A';
      }
    }
  }


}
