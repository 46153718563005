import { DEV_MODE, TEST_DEPLOY } from './Defines';

interface Interface_ConfigServer
{
    DOMAIN: string;
    API_USER_COUNTER: string;
    API_USER_HEALTH: string;
    API_USER_CREATE: string;
    API_USER_LOAD_PROFILE: string;
    API_USER_UPDATE_PROFILE: string;
    API_USER_UPDATE_PROFILE_INSTITUTE: string;
    API_RATER_SUBMIT_SURVEY: string;
    API_RATER_DOWNLOAD_SURVEY: string;
    API_RATER_RATE_MESH: string;
    API_RATER_SUBMIT_PATCH: string;
    API_MESH_UPLOAD: string;
    API_MESH_DOWNLOAD: string;
    API_MESH_DELETE: string;
    API_PREDICTION_LOAD: string;
    API_PREDICTION_LOAD_ALL: string;
    API_PREDICTION_UPLOAD: string;
    API_PREDICTION_CHANGE_STATUS: string;
    API_PREDICTION_DOWNLOAD_VISUALIZATION: string;
    API_PREDICTION_DOWNLOAD_PREPROCESSING: string;
    API_PATCH_ASSIGN: string;
    API_PATCH_DATA: string;
}
  
export const CONFIG_SERVER: Interface_ConfigServer = 
{
    // --- LOCAL OR LIVE BACKEND SERVER ---
    DOMAIN: DEV_MODE ? //'https://localhost:5001' :
                       // note: vmware Windows requires explicit ip; find in Windows settings -> ethernet
                       // for example:
                       'https://192.168.158.2:5001/' :
                    //    'http://localhost:44386' :
                       'https://www.craniorate.net/',

    // DOMAIN: 'https://craniorateweb-env.tak7rd3v47.us-west-1.elasticbeanstalk.com/',
    // DOMAIN: 'http://awseb-e-7-AWSEBLoa-1EIKFBHA14BBO-93392139.us-west-1.elb.amazonaws.com/',
    // DOMAIN: 'http://ec2-54-153-113-148.us-west-1.compute.amazonaws.com/',
    API_USER_COUNTER: 'api/users/',
    API_USER_HEALTH: 'api/users/health',
    API_USER_CREATE: 'api/users/create/',
    API_USER_LOAD_PROFILE: 'api/users/profile/',
    API_USER_UPDATE_PROFILE: 'api/users/profile/',
    API_USER_UPDATE_PROFILE_INSTITUTE: 'api/users/update_profile_institute/',
    API_RATER_SUBMIT_SURVEY: 'api/raters/survey_submit/',
    API_RATER_DOWNLOAD_SURVEY: 'api/admins/rater_survey_data/',
    API_RATER_RATE_MESH: 'api/raters/mesh_rate/',
    API_RATER_SUBMIT_PATCH: 'api/raters/patch_submit/',
    API_MESH_UPLOAD: 'api/meshes/upload',
    API_MESH_DOWNLOAD: 'api/meshes/download/',
    API_MESH_DELETE: 'api/meshes/delete/',
    API_PREDICTION_LOAD: 'api/predictions/get_result',
    API_PREDICTION_LOAD_ALL: 'api/predictions/get_all_results',
    API_PREDICTION_UPLOAD: 'api/predictions/upload_prediction',
    API_PREDICTION_CHANGE_STATUS: 'api/predictions/change_status',
    API_PREDICTION_DOWNLOAD_VISUALIZATION: 'api/predictions/get_visualization',
    API_PREDICTION_DOWNLOAD_PREPROCESSING: 'api/predictions/get_preprocessing',
    API_PATCH_ASSIGN: 'api/patches/assign/',
    API_PATCH_DATA: 'api/patches/rating_data/'
};
