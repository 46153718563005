import { Injectable } from '@angular/core';
import { Router } from '@angular/router';
import { HttpClient, HttpHeaders, HttpResponse, HttpParams } from '@angular/common/http';

import { CONFIG_SERVER } from '../../Configurations/Config_Server';
import * as DEFINES from '../../Configurations/Defines';
import { Interface_InfoPrediction_Basic } from 'src/app/Models/InfoPrediction';

import { Auth0Service } from '../Auth0/auth0.service';
import { UserService } from '../User/user.service';
import { Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})

export class PredictionService
{

  public id_prediction_test: string = "";

  public info_prediction: Interface_InfoPrediction_Basic =
  {
    id: null,
    id_requester: null,
    name: null,
    mesh_id: null,
    visualization_id: null,
    status: null,
    max_deviation: 0,
    ppca_mahalanobis_above_metopic_5sigma: DEFINES.PPCA_MAHALANOBIS_ABOVE_METOPIC_5SIGMA,
    severity_score: DEFINES.METOPIC_SEVERITY_SCORE_PREDICTION_UNDEFINED,
    severity_percentile: DEFINES.METOPIC_SEVERITY_PERCENTILE_PREDICTION_UNDEFINED,
    date_time_created: '',
    date_time_uploaded_local: '',
    gender: '',
    race: '',
    diagnosis: '',
    age_scan: 0,
    comments: '',
  };

  public isDownloadingVisualizationData: boolean = false;

  public map_visualization_data: Map<string, string> = new Map<string, string>();

  public list_prediction: Interface_InfoPrediction_Basic[] = [];

  constructor(public router: Router, public serviceAuth: Auth0Service, public serviceUser: UserService, private httpClient: HttpClient)
  {

  }

  Send_LoadPrediction(id_prediction: string): Observable<any>
  {
    let url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_PREDICTION_LOAD;
    // var body = JSON.stringify(this.info);
    let idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('id_prediction', id_prediction);

    let accessToken = this.serviceAuth.AccessToken;
    let authorizationValue = "Bearer " + accessToken;
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'application/json'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.get<any>(url, requestOptions);
  }

  Send_LoadAllPredictions(): Observable<any>
  {
    if (!this.serviceAuth.IsAuthenticated())
      this.serviceAuth.Login();

    let url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_PREDICTIONS_GET_ALL;
    // var body = JSON.stringify(this.info);
    let idAuth0 = this.serviceAuth.IdAuth0;
    let paramsIDToken = new HttpParams().set('id_auth0', idAuth0);

    let accessToken = this.serviceAuth.AccessToken;
    let authorizationValue = "Bearer " + accessToken;
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'application/json'
    };

    return this.httpClient.get<any>(url, requestOptions);
  }

  public Send_ChangeStatus(id_prediction: string, status_new: string): Observable<any>
  {
    var url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_PREDICTION_CHANGE_STATUS.replace("{id}", id_prediction);

    var idToken = this.serviceAuth.IdToken;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('status', status_new);

    var accessToken = this.serviceAuth.AccessToken;
    var authorizationValue = "Bearer " + accessToken;
    var headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'application/json'
    });

    const requestOptions: Object = {
      headers,
      params: paramsIDToken,
      responseType: 'text'
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.put<any>(url, "", requestOptions);

  }


  Send_DownloadVisualization(id_prediction: string, visualization_id: string, callbackLoadFinished): Observable<any>
  {
    let url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_PREDICTION_DOWNLOAD_VISUALIZATION.replace("{id}", id_prediction);
    // var body = JSON.stringify(this.info);
    let idToken = this.serviceAuth.IdToken;
    let paramsIDs = new HttpParams().set('id_token', idToken)
                                    .set('id_prediction', id_prediction)
                                    .set('visualization_id', visualization_id);

    let accessToken = this.serviceAuth.AccessToken;
    let authorizationValue = "Bearer " + accessToken;
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
      'Content-Type': 'text/plain'
    });

    const requestOptions: Object =
    {
      headers,
      params: paramsIDs,
      responseType: "text/plain"
    };

    // return this.httpClient.get<string>(url, requestOptions);
    return this.httpClient.get<any>(url, requestOptions);
  }

  Send_DownloadPreprocessing(id_prediction: string, visualization_id: string): Observable<Blob>
  {
    let url: string = CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_PREDICTION_DOWNLOAD_PREPROCESSING.replace("{id}", id_prediction);
    let idToken = this.serviceAuth.IdToken;
    let paramsIDs = new HttpParams().set('id_token', idToken)
                                    .set('id_prediction', id_prediction)
                                    .set('visualization_id', visualization_id);

    let accessToken = this.serviceAuth.AccessToken;
    let authorizationValue = "Bearer " + accessToken;
    let headers: HttpHeaders = new HttpHeaders({
      Authorization: authorizationValue,
    });

    const requestOptions: Object =
    {
      headers,
      params: paramsIDs,
      responseType: "json"
    };

    return this.httpClient.get<Blob>(url, requestOptions);
  }


  public SetVisualizationDownloadingStatus(isDownloading: boolean)
  {
    this.isDownloadingVisualizationData = isDownloading;
  }

  public LoadVisualizationDataFromLocal(visualization_id: string): string
  {
    if(this.map_visualization_data.has(visualization_id))
    {
      return this.map_visualization_data.get(visualization_id);
    }

    return null;
  }

  public SaveVisualizationDataToLocal(visualization_id: string, data: string)
  {
    if(!this.map_visualization_data.has(visualization_id))
    {
      this.map_visualization_data.set(visualization_id, data);
    }
  }

}
