import { Component, OnInit, AfterViewInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import * as DEFINES from 'src/app/Configurations/Defines';
import { Auth0Service } from '../../Services/Auth0/auth0.service';
import { UserService } from 'src/app/Services/User/user.service';
import { MeshService } from 'src/app/Services/Mesh/mesh.service';
import { Interface_InfoUser_Basic } from 'src/app/Models/InfoUser';
import { UserProfileModalComponent } from 'src/app/Widgets/user-profile-modal/user-profile-modal.component';
import { RaterSurveyModalComponent } from 'src/app/Widgets/rater-survey-modal/rater-survey-modal.component';
import { UserAgreementModalComponent } from 'src/app/Widgets/user-agreement-modal/user-agreement-modal.component';
import { MaintenanceModalComponent } from 'src/app/Widgets/maintenance-modal/maintenance-modal';

// import 'bootstrap';
// import {
//   trigger,
//   state,
//   style,
//   animate,
//   transition
// } from '@angular/animations';


@Component({
  selector: 'app-main',
  templateUrl: './main.component.html',
  styleUrls: ['./main.component.css'],
  // animations: [
  //   trigger,
  //   state,
  //   style,
  //   animate,
  //   transition
  // ]
})
export class MainComponent implements OnInit, AfterViewInit
{
  public isIEOrEdge = false;
  public isFirstActive = true;
  public isSecondActive = false;
  public isThirdActive = false;

  public indexCurrent = 0;

  imageCarousel00 = `https://picsum.photos/900/500?random&t=${Math.random()}`;
  imageCarousel01 = `https://picsum.photos/900/500?random&t=${Math.random()}`;
  imageCarousel02 = `https://picsum.photos/900/500?random&t=${Math.random()}`;

  imageHeading00 = `https://picsum.photos/900/500?random&t=${Math.random()}`;
  imageHeading01 = `https://picsum.photos/900/500?random&t=${Math.random()}`;
  imageHeading02 = `https://picsum.photos/900/500?random&t=${Math.random()}`;

  imageFeature00 = `https://picsum.photos/900/500?random&t=${Math.random()}`;
  imageFeature01 = `https://picsum.photos/900/500?random&t=${Math.random()}`;
  imageFeature02 = `https://picsum.photos/900/500?random&t=${Math.random()}`;

  imageSignup = '../../../assets/MainPage/signup.png';
  imageDataScience = '../../../assets/MainPage/datascience.png';
  imageTutotial = '../../../assets/MainPage/tutorial.png';

  imageSkull = '../../../assets/MainPage/skull.png';
  imageDiagnosis = '../../../assets/MainPage/diagnosis.png';
  imagePrediction = '../../../assets/MainPage/prediction.png';

  imageMC = '../../../assets/MainPage/MC.png';
  imageProjectGoals = '../../../assets/MainPage/projectgoals.png';
  imageTeam = '../../../assets/MainPage/team.png';

  imageNIH = '../../../assets/MainPage/NIH.jpg';
  imagePIT = '../../../assets/MainPage/pittsburgh.jpg';
  imageSOC = '../../../assets/MainPage/soc.png';
  imageUTAH = '../../../assets/MainPage/utah.jpg';
  

  images = [0, 1, 2, 3, 4, 5, 6, 7].map(() => `https://picsum.photos/900/500?random&t=${Math.random()}`);

  constructor(public router: Router, public serviceAuth: Auth0Service, public serviceUser: UserService, public serviceMesh: MeshService, private serviceNgModal: NgbModal) 
  { 
    // this doesn't seem to be necessary
    //serviceAuth.HandleAuthentication();
    

    // UNCOMMENT IF UNDERGOING MAINTENANCE AND REQUIRE NOTIFICATION
    // var modalActive = this.serviceNgModal.open(MaintenanceModalComponent, { size: 'lg'});
    // modalActive.componentInstance.isPreview = true;
  }

  ngOnInit() 
  {
    // $('.carousel').carousel();
    // alert("Hello Cranio!");

    this.isIEOrEdge = /msie\s|trident\/|edge\//i.test(window.navigator.userAgent);

    if (this.serviceAuth.IsAuthenticated()) 
    {
      // this.auth.renewTokens();

      // this.router.navigate(['/profile']);
      this.OnUserLogin();

      // var accessToken: string = this.auth.AccessToken;
      // var asdf = 0;
    }
    else
    {
      
    }
    
  }

  ngAfterViewInit(): void
  {
    // this.TestRaterSurvey();

    // this.TestConfirmDialog();
  }

  public OnPrevImage() {
    this.indexCurrent = this.indexCurrent-1;
    if(this.indexCurrent<0)
    {
      this.indexCurrent = 0;
      
    }
  }

  public OnNextImage() {
    this.indexCurrent = this.indexCurrent+1;
    if(this.indexCurrent > 2)
    {
      this.indexCurrent = 2;
    }
  }


  

  public OnUserLogin()
  {
    this.serviceUser.SetLoadingStatus(true, false);
    this.serviceUser.Send_LoadProfile().subscribe(
      respMessageSuccess=>{
        var userInfo: Interface_InfoUser_Basic = JSON.parse(respMessageSuccess);
        this.serviceUser.info = userInfo[0];
        this.serviceUser.isForbidden = false;
        this.serviceMesh.ReloadPatchInfoFromUserService();
        // alert("Load User Profile: Successed!");
        // this.serviceUser.SetLoadingStatus(false, true);
        this.serviceUser.SetLoadingStatus(false, false);

        // setTimeout(()=>{
        //   this.serviceUser.SetLoadingStatus(false, false);
        // }, DEFINES.TIME_SHOWING_SUCCESS_ALERT);

        // if(this.serviceUser.info.rater_survey_info == null || 
        //   this.serviceUser.info.rater_survey_info.is_survey_submitted == undefined ||
        //   this.serviceUser.info.rater_survey_info.is_survey_submitted == null ||
        //   this.serviceUser.info.rater_survey_info.is_survey_submitted==false)

        // user institute is needed, though account has been created
        if(this.serviceUser.info.institute == null ||
          this.serviceUser.info.institute.length == 0 )
        {
          var modalActive = this.serviceNgModal.open(UserAgreementModalComponent, { size: 'lg'});
          modalActive.componentInstance.infoUser = this.serviceUser.info;
        }
        else
        {
          this.router.navigate(['/prediction-physician']);
          // this.router.navigate(['/prediction-physician']);
        }

        // this.router.navigate(['/prediction-physician']);
        
      },
      respMessageError=>{
        console.log(respMessageError);

        this.serviceUser.SetLoadingStatus(false, false);
        
        var strTemp: string = "TempString";
        alert("You must agree to the Terms before proceeding.");

        // if a new user, this will also create the user (mongo db entry, etc)
        var modalActive = this.serviceNgModal.open(UserAgreementModalComponent);
        modalActive.componentInstance.isPreview = false;
        
      }
    );
    var asdf = 0;
  }

  public TestRaterSurvey()
  {
    setTimeout(() => {
      var modalActive = this.serviceNgModal.open(RaterSurveyModalComponent);// {windowClass: 'window_modal'});
      var infoTest: Interface_InfoUser_Basic = {
        // For test only
        type_account: "Admin",
        first_name: "TestFirstName",
        last_name: "TestLastName",
        is_first_login: false,
        gender: "Male",
        age: 35,
        date_of_birth: "19861205",
        address: 
        {
          street: "TestStreet",
          city: "TestCity",
          state: "TestState",
          zip_code: 11111,
        },
        list_patch: [],
        professional_info:
        {
          list_areas_of_practice: [ "AREA_AAA", "AREA_BBB", "AREA_CCC"],
          list_job_info: 
          [
            {job_title: "Job_A", name_institution: "Inst_A", num_of_years: 1},
            {job_title: "Job_B", name_institution: "Inst_B", num_of_years: 2},
            {job_title: "Job_C", name_institution: "Inst_C", num_of_years: 3},
          ],
          frequency_craniosynostosis_surgery: "NA-FREQUENCY"
        },
        rater_survey_info:
        {
          // is_forbidden: false,
          is_survey_submitted: false,
          area_of_practice: DEFINES.QUESTION_SURVEY_UNANSWERED,
          practice_setting: DEFINES.QUESTION_SURVEY_UNANSWERED,
          current_surgical_status: DEFINES.QUESTION_SURVEY_UNANSWERED,
          in_practice_years: DEFINES.QUESTION_SURVEY_UNANSWERED,
          job_title: DEFINES.QUESTION_SURVEY_UNANSWERED,
          surgery_frequency: DEFINES.QUESTION_SURVEY_UNANSWERED,
          num_of_annual_patients: DEFINES.QUESTION_SURVEY_UNANSWERED,
          provided_email: ""
        }
      }
      // this.serviceUser.info = infoTest;
      modalActive.componentInstance.infoUser = infoTest;
    })
  }


  public FunctionTestYes()
  {
    alert("Yes!");
  }

  public FunctionTestNo()
  {
    alert("No!");
  }

  public OnTerms()
  {
    var modalActive = this.serviceNgModal.open(UserAgreementModalComponent);// {windowClass: 'window_modal'});
    modalActive.componentInstance.isPreview = true;
  }

}
