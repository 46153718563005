import { Component, OnInit } from '@angular/core';

import { Router } from '@angular/router';
import { NgbModal, NgbActiveModal } from '@ng-bootstrap/ng-bootstrap';
import { Injectable } from '@angular/core';

@Component({
  selector: 'app-maintenance-modal',
  templateUrl: './maintenance-modal.component.html',
  styleUrls: ['./maintenance-modal.component.css']
})
@Injectable()
export class MaintenanceModalComponent implements OnInit
{

  constructor(public router: Router, private modalService: NgbModal, public activeModal: NgbActiveModal) 
  { 

  }

  ngOnInit() 
  {

  }

  OnClose()
  {
    this.activeModal.close();
  }

}
