import { Component, OnInit } from '@angular/core';
import { BsModalRef } from 'ngx-bootstrap/modal';
import { ProgressbarModule } from 'ngx-bootstrap/progressbar';

import { HttpEvent, HttpEventType, HttpErrorResponse, HttpParams, HttpResponse, HttpHeaderResponse } from '@angular/common/http';
import { UploadEvent, UploadFile, FileSystemFileEntry, FileSystemDirectoryEntry } from 'ngx-file-drop';

import * as JSZip from 'jszip';

import { Auth0Service } from 'src/app/Services/Auth0/auth0.service';
import { UserService } from 'src/app/Services/User/user.service';
import { PredictionService } from 'src/app/Services/Prediction/prediction.service';
import { FileService } from 'src/app/Services/File/file.service';
import { throwError } from 'rxjs';
import { FormGroup, FormBuilder } from '@angular/forms';
import { CONFIG_SERVER } from 'src/app/Configurations/Config_Server';
import { INVALID_DIRECTORY_NAME } from 'src/app/Configurations/Defines';

import {formatDate} from '@angular/common';

import * as DicomParser from 'dicom-parser';
import { CRScans } from './CRScans';

@Component({
  selector: 'app-prediction-upload',
  templateUrl: './prediction-upload.component.html',
  styleUrls: ['./prediction-upload.component.css']
})
export class PredictionUploadComponent implements OnInit
{
  // fileProfileForm: FormGroup;

  list_files_dropped: UploadFile[] = [];

  // str_id_zip_name: string = "ZIP_ID";

  // str_zip_upload_progress: string = "";

  // str_zip_upload_progress_percentage: string = "";

  TYPE_PROGRESS_READY: string = "secondary";
  TYPE_PROGRESS_COMPRESSING: string = "warning";
  TYPE_PROGRESS_UPLOADING: string = "info";
  TYPE_PROGRESS_FINALIZING: string = "primary";
  TYPE_PROGRESS_COMPLETED: string = "success";

  NUM_PROGRESS_READY: number = 0;
  NUM_PROGRESS_COMPRESSING_MIN: number = 0;
  NUM_PROGRESS_COMPRESSING_VOLUMN: number = 20;
  NUM_PROGRESS_UPLOADING_MIN: number = 20;
  NUM_PROGRESS_UPLOADING_VOLUMN: number = 60;
  NUM_PROGRESS_FINALIZING: number = 80;
  NUM_PROGRESS_COMPLETED: number = 100;

  TEXT_PROGRESS_READY: string = "Ready: ";
  TEXT_PROGRESS_COMPRESSING: string = "Compressing: ";
  TEXT_PROGRESS_UPLOADING: string = "Uploading: ";
  TEXT_PROGRESS_FINALIZING: string = "Finalizing: ";
  TEXT_PROGRESS_COMPLETED: string = "Completed: ";

  file_upload: File;

  str_id: string = "___DDD___";

  str_name: string = "";

  str_diagnosis: string = "";

  date_birth: string = "";

  date_scan: string = "";

  age_scan: string = "";

  str_gender: string = "";

  str_race: string = "";

  str_comments: string = "";

  str_upload_progress: string = "";

  str_upload_progress_percentage: string = "";

  status_progress: string = "Ready For Uploading";

  num_progress: number = this.NUM_PROGRESS_READY;

  type_progress: string = this.TYPE_PROGRESS_READY;

  text_progress:string = this.TEXT_PROGRESS_READY + this.NUM_PROGRESS_READY.toString() + "%";

  is_in_progress: boolean = false;
  dirname: string = INVALID_DIRECTORY_NAME;
  scans = new CRScans();

  terms_agreed: boolean = false;

  public status_file_upload = {status: '', message: '', filePath: ''};

  constructor(public ref_modal: BsModalRef, public serviceAUTH: Auth0Service, public serviceUSER: UserService, public servicePREDICTION: PredictionService, public serviceFILE: FileService, private formBuilder: FormBuilder)
  {
  }


  // str_name : string = "Case Identifier need to be unique.";
  ngOnInit()
  {
    this.str_name = 'scan'+ formatDate(new Date(), '-yyyy-MM-dd-HHmmss', 'en');;
  }

  public filterCaseIdentifier(s: string){
    return s.replace(/[^a-z0-9_\-]/gi, '_').toLowerCase();
  }

  public calculateAgeScan(
    date_birth: string,
    date_scan: string,
  ){
    let d_split0 = date_birth.split('-', 3);
    let d_split1 = date_scan.split('-', 3);

    let y0 = parseInt(d_split0[0]);
    let y1 = parseInt(d_split1[0]);

    let m0 = parseInt(d_split0[1]);
    let m1 = parseInt(d_split1[1]);

    let d0 = parseInt(d_split0[2]);
    let d1 = parseInt(d_split1[2]);

    // age in months
    let age_at_scan = (y1-y0) * 12 + (m1-m0);
    if (d1-d0 >= 15){
      age_at_scan = age_at_scan + 1;
    }else{
      if (d1-d0 <= -15){
        age_at_scan = age_at_scan - 1;
      }
    }

    return age_at_scan
  }

  public UpdateAgeAtScan(){
    if (
      this.date_birth != null &&
      this.date_birth.length >0 &&
      this.date_scan != null &&
      this.date_scan.length >0 ){

      // aotu fill "age at scan"
      this.age_scan = this.calculateAgeScan(
        this.date_birth,
        this.date_scan,
      ).toString();
      }

    return
  }

  private DropFailed(error: string, showError: boolean = true) {
    this.list_files_dropped = [];
    this.scans = new CRScans();
    if (showError) {
      alert(error);
    }
  }

  public async OnDicomFilesDropped(event: UploadEvent) {
    this.dirname = INVALID_DIRECTORY_NAME;
    this.is_in_progress = true;
    for (const droppedFile of event.files) {

      if (droppedFile.fileEntry.isFile) {
        const fileEntry = droppedFile.fileEntry as FileSystemFileEntry;
        //console.log(`filename is ${fileEntry.name}`);
        try {
          await this.scans.addScan(fileEntry);

          // add filename to scans list
          this.list_files_dropped.push(droppedFile);
        }
        catch(e) {
          console.log(`failed to add file to scans: ${e}`);
        }
      }
      else {
        const dirEntry = droppedFile.fileEntry as FileSystemDirectoryEntry;
        const dirname = (dirEntry as any).fullPath;
        console.log(`directory: {dirname}`); // its contents are already included
      }
    }
    this.is_in_progress = false;
  }

  public OnTermsCheckboxClick()
  {
    this.terms_agreed = !this.terms_agreed;
  }

  public ClearScans()
  {
    this.DropFailed("scans cleared", false);
  }

  public VerifyFormInput(show_alert = false) {
    if (this.str_name == null || this.str_name.length <= 0)
    {
      if (show_alert)
        alert("Patient ID should not be empty!")
      return false;
    }

    // TODO: check other necessary form entries

    return true;
  }

  public async OnCompressUploadFile()
  {
    if (!this.VerifyFormInput(true)) {
      return false;
    }

    // 1. get the best entry from this.scans,
    // 2. collect its files to be compressed and uploaded,
    // 3. show user what will be uploaded (scan metadata and number of files)
    // 4. compress and upload the files if they confirm

    try {
      var upload_scan = this.scans.bestScan(); // throws if no sufficient scan exists
      var str = "Series " + String(upload_scan.scan.series_no) + " selected from the " + String(this.scans.scans.size) + " instances in this study.\n\nDO YOU WISH TO CONTINUE?\n\nDetails:\n\tNumber of slices: " + String(upload_scan.files.length) + "\n\tSlice thickness: " + upload_scan.scan.shape[2] + "\n\tScan Details: " + upload_scan.scan.toStr();
      console.log(str);

      // get user confirmation
      if (!window.confirm(str)) {
        return;
      }

      // add files to zip to be uploaded
      var files = upload_scan['files'];
      var zipped_file = new JSZip();
      for (const fileEntry of files) {
        console.log(`adding ${fileEntry.name}...`);
        var file = await CRScans.getFile(fileEntry) as File;
        zipped_file.file(file.name, file);
      }
      if (Object.entries(zipped_file.files).length === 0)
      {
        alert("unexpected error preparing files for upload");
        return false;
      }

      this.num_progress = this.NUM_PROGRESS_COMPRESSING_MIN;
      this.type_progress = this.TYPE_PROGRESS_COMPRESSING;
      let num_rounded = Math.round((this.num_progress + Number.EPSILON) * 100) / 100;
      this.text_progress = this.TEXT_PROGRESS_COMPRESSING + num_rounded.toString() + "%";
      this.is_in_progress = true;
      this.terms_agreed = false; // reset it for next upload

      var component = this;
      var id_zip = this.str_name;
      zipped_file.generateAsync({
        type:"blob",
        compression: "DEFLATE",
        compressionOptions: {
          level: 9
        }
      }, function updateCallback(metadata) {
        component.num_progress = component.NUM_PROGRESS_COMPRESSING_MIN + (component.NUM_PROGRESS_COMPRESSING_VOLUMN * metadata.percent / 100);
        component.type_progress = component.TYPE_PROGRESS_COMPRESSING;
        let num_rounded = Math.round((component.num_progress + Number.EPSILON) * 100) / 100;
        component.text_progress = component.TEXT_PROGRESS_COMPRESSING + num_rounded.toString() + "%";
      }).then(function (blob) {
        let b: any = blob;
        // A Blob() is almost a File() - it's just missing the two properties below which we will add
        b.lastModifiedDate = new Date();
        b.name = id_zip;

        let file = <File>blob;
        component.CompressCallBack(file);
      });
    }
    catch(ex) {
      alert("no suitable DICOM volumes found to upload: " + ex);
      return false;
    }
  }

  public CompressCallBack(file_zipped: File)
  {
    let urlPredictionUpload: string= CONFIG_SERVER.DOMAIN+CONFIG_SERVER.API_PREDICTION_UPLOAD;

    let idToken = this.serviceAUTH.IdToken;
    let auth0Id = this.serviceAUTH.IdAuth0;
    let paramsIDToken = new HttpParams().set('id_token', idToken)
                                        .set('id_auth0', auth0Id)
                                        .set('name_prediction', this.str_name)
                                        .set('diagnosis_prediction', this.str_diagnosis)
                                        .set('date_birth_prediction', this.date_birth)
                                        .set('date_scan_prediction', this.date_scan)
                                        .set('age_scan_prediction', this.age_scan)
                                        .set('gender_prediction', this.str_gender)
                                        .set('race_prediction', this.str_race)
                                        .set('comments_prediction', this.str_comments);

    this.num_progress = this.NUM_PROGRESS_UPLOADING_MIN;
    this.type_progress = this.TYPE_PROGRESS_UPLOADING;
    let num_rounded = Math.round((this.num_progress + Number.EPSILON) * 100) / 100;
    this.text_progress = this.TEXT_PROGRESS_UPLOADING + num_rounded.toString() + "%";

    this.serviceFILE.Send_UploadFileV2(file_zipped, urlPredictionUpload, paramsIDToken).subscribe(
      event => {
        if (event.type === HttpEventType.UploadProgress)
        {
          const percentDone = Math.round(100 * event.loaded / event.total);
          console.log("--------------------------------------");

          this.str_upload_progress = event.loaded.toString() + "/" + event.total.toString();
          this.str_upload_progress_percentage = (100 * event.loaded / event.total).toString()+"%";
          console.log(this.str_upload_progress);
          console.log(this.str_upload_progress_percentage);

          if(event.loaded < event.total)
          {
            this.num_progress = this.NUM_PROGRESS_UPLOADING_MIN + (this.NUM_PROGRESS_UPLOADING_VOLUMN * event.loaded / event.total);
            this.type_progress = this.TYPE_PROGRESS_UPLOADING;
            let num_rounded = Math.round((this.num_progress + Number.EPSILON) * 100) / 100;
            this.text_progress = this.TEXT_PROGRESS_UPLOADING + num_rounded.toString() + "%";
          }
          else if (event.loaded == event.total) // if finalizing?
          {
            this.num_progress = this.NUM_PROGRESS_FINALIZING;
            this.type_progress = this.TYPE_PROGRESS_FINALIZING;
            let num_rounded = Math.round((this.num_progress + Number.EPSILON) * 100) / 100;
            this.text_progress = this.TEXT_PROGRESS_FINALIZING + num_rounded.toString() + "%";
          }
        }
        else if (event.type === HttpEventType.Response && (event.status === 200 || event.status === 201))  // if successful
        {
          this.servicePREDICTION.info_prediction.id = event.body.mesh_id;
          console.log("Prediction ID: ", this.servicePREDICTION.info_prediction.id, event.body.mesh_id);

          this.num_progress = this.NUM_PROGRESS_COMPLETED;
          this.type_progress = this.TYPE_PROGRESS_COMPLETED;
          let num_rounded = Math.round((this.num_progress + Number.EPSILON) * 100) / 100;
          this.text_progress = this.TEXT_PROGRESS_COMPLETED + num_rounded.toString() + "%";

          this.is_in_progress = false;
          this.list_files_dropped = [];

          alert('Success!\nYour scan is in the queue to be processed. You will receive an email notification when it is finished.\nYou can close the current window and click "Refresh Scans" to view its status.');

          // close modal on completion
          this.close();
        }

      },
      respMessageError=>{
        console.log(respMessageError);

        alert("Upload File Failed: \nMessage: " + respMessageError.error);

        this.is_in_progress = false;
      }
    );
  }

  public FileOver(event)
  {
    console.log(event);
  }

  public FileLeave(event)
  {
    console.log(event);
  }

  close() {
    this.ref_modal.hide();
  }

}
